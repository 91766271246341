<script setup>
defineComponent('NuLeftSideBar')

const {$bootstrap} = useNuxtApp()
const {dayjs} = useDayjs()
const {t} = useI18n()
const app = useNuxtApp()
const localePath = useLocalePath()
const router = useRouter()
const footerItems = useRuntimeConfig().public.footer?.footerItems
const sideNavBarItems = useRuntimeConfig().public.sideNavbarConfig?.sideNavBarItems
const {$isInMobileAppWebView} = useNuxtApp()
const {postMessageMyProfile, postMessageLogin} = usePostMessage()

const auth = useAuthStore()
const sideNavBarList = computed(() => (sideNavBarItems?.length > 0 ? sideNavBarItems : footerItems))
const emit = defineEmits(['openAuthPanelClick'])

const hideSideBar = () => {
  let element = document.getElementById('nu-left-side-bar-offcanvas')
  let instance = $bootstrap?.Offcanvas.getInstance(element)
  instance?.hide()
}

const handleLoginClick = () => {
  if ($isInMobileAppWebView.value) {
    postMessageLogin()
    return
  }
  emit('openAuthPanelClick')
}

const handleMyProfile = () => {
  if ($isInMobileAppWebView.value) {
    postMessageMyProfile()
    return
  }

  router.push(auth?.isLoggedIn ? localePath({name: 'profile-my-preferences'}) : '/')
  hideSideBar()
}

const doLogout = () => {
  auth.logout()
}
const footerCopyright = ref(
  t('comp_nu_left_sidebar.copyright', {
    company: (app.$themeData && app.$themeData.name) || '',
    year: dayjs().year(),
  }),
)
</script>

<style lang="scss" scoped>
@import 'assets/css/components/global/nu-left-side-bar.css';
</style>

<template>
  <div class="nu-left-side-bar">
    <div class="nu-side-bar-header logged-user-email">
      <NuxtLink
        :class="{'user-email': auth?.isLoggedIn}"
        data-testid="user-profile"
        @click="auth?.isLoggedIn ? handleMyProfile() : handleLoginClick()"
      >
        {{ auth?.isLoggedIn ? auth?.user?.email : $t('comp_nu_left_sidebar.login_register_user') }}
      </NuxtLink>
    </div>
    <div class="nu-side-bar-content">
      <ul>
        <li
          :key="index"
          v-for="(item, index) in sideNavBarList"
        >
          <NuxtLink
            :to="localePath({path: `${item.url}`})"
            @click="hideSideBar()"
          >
            {{ t(`${sideNavBarItems ? 'comp_nu_left_sidebar' : 'comp_nu_footer'}.${item.text}`) }}
          </NuxtLink>
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </li>
      </ul>
    </div>
    <div class="nu-side-bar-footer">
      <NuSocialLinks />
      <NuPaymentIcons />
      <div class="nu-side-bar-copyright">{{ footerCopyright }}</div>
      <div
        class="nu-side-bar-logout"
        v-if="auth?.isLoggedIn"
      >
        <NuxtLink @click="doLogout">{{ $t('comp_nu_left_sidebar.logout') }}</NuxtLink>
      </div>
    </div>
  </div>
</template>
